var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"screen-card",class:[
    ("screen-card--" + _vm.ratio),
    { 'screen-card--no-overflow': _vm.overflowHidden, 'screen-card--full-height': _vm.fullHeight } ]},[(_vm.title !== '')?_c('header',{staticClass:"screen-card__header"},[_c('section',{staticClass:"screen-card__header__section screen-card__header__section--align-start"},[(_vm.isLoading)?_c('skeleton-line',{attrs:{"height":"20px"}}):(_vm.logo)?_c('ui-logo',{staticClass:"screen-card__header__section__logo",attrs:{"logo":_vm.logo,"width":"90px","height":"auto"}}):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"screen-card__header__section__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.isLoading && _vm.subTitle)?_c('skeleton-line',{attrs:{"height":"15px","margin-top":"3px"}}):(!_vm.isLoading && _vm.subTitle)?_c('div',{staticClass:"screen-card__header__section__sub-title"},[_vm._v(" "+_vm._s(_vm.subTitle)+" ")]):_vm._e()],1),_c('section',{staticClass:"screen-card__header__section screen-card__header__section--align-end"},[_vm._t("header-right")],2)]):_vm._e(),_c('div',{staticClass:"screen-card__body",class:{
      'screen-card__body--no-title': _vm.title === '' && _vm.noPadding === false,
      'screen-card__body--no-padding': _vm.noPadding === true,
      'screen-card__body--align-center': _vm.align === 'center',
      'screen-card__body--relative': _vm.isRelative,
    }},[(_vm.display === 'grid')?_c('div',{staticClass:"screen-card__body__grid"},[_vm._t("body")],2):(_vm.display === 'flex')?_c('div',{staticClass:"screen-card__body__flex",class:{ 'screen-card__body__flex--full-height': _vm.fullHeight }},[_vm._t("body")],2):[_vm._t("body")]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }