<template>
  <div class="screen-grid" :class="{ 'screen-grid--full-height': fullHeight, 'screen-grid--with-margin': withMargin }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ScreenGrid',
  props: {
    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    withMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.screen-grid {
  width: 100%;

  @media (min-width: $screen-sm) {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin: (-$gutter-tablet / 2);
    width: calc(100% + #{$gutter-tablet});
  }

  @media (min-width: $screen-xl) {
    margin: (-$gutter-desktop / 2);
    width: calc(100% + #{$gutter-desktop});
  }

  &--full-height {
    @media (min-width: $screen-md) {
      height: 100%;
    }
  }

  &--with-margin {
    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet - $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop - $gutter-desktop / 2;
    }
  }
}
</style>
