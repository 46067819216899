<template>
  <div class="screen-header">
    <div class="screen-header__container">
      <section class="screen-header__container__section screen-header__container__section--align-start">
        <skeleton-line height="23px" v-if="isLoading" />
        <slot name="title" v-else><ui-title :title="title" /></slot>
        <span v-if="isBeta && !isLoading" class="screen-header__container__section__beta">
          {{ $t('common.label.beta') }}
        </span>
        <skeleton-line height="20px" v-if="isLoading" margin-top="3px" width="50%" />
        <screen-breadcrumb :breadcrumbs="breadcrumbs" class="screen-header__container__section__breadcrumb" v-else />
      </section>
      <section
        class="screen-header__container__section screen-header__container__section--align-end"
        v-if="rightContent"
      >
        <slot></slot>
      </section>
    </div>
  </div>
</template>

<script>
import ScreenBreadcrumb from '@/components/Screen/Breadcrumb.vue'
import UiTitle from '@/components/UI/Title.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'

export default {
  name: 'ScreenHeader',
  components: {
    ScreenBreadcrumb,
    UiTitle,
    SkeletonLine,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    breadcrumbs: {
      type: Array,
      required: true,
    },
    rightContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBeta: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.screen-header {
  padding: $gutter-mobile 0;

  @media (min-width: $screen-sm) {
    padding: $gutter-tablet 0;
  }

  @media (min-width: $screen-xl) {
    padding: $gutter-desktop 0;
  }

  &__container {
    @include container;

    &__section {
      display: flex;
      height: 100%;

      &--align-start {
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        overflow: hidden;
        white-space: nowrap;
      }

      &--align-end {
        justify-content: flex-end;
      }

      &__breadcrumb {
        margin-top: 3px;
      }

      &__beta {
        color: $orange-tree-poppy;
        font-size: $font-size-sm;
        font-weight: $font-weight-700;
      }
    }
  }
}
</style>
